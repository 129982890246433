import React from 'react';
import styled, { css } from 'styled-components';
import { PageLayoutComponent } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { EntityTypeBannersProvider, ExplorerLayout, PageHalfBanner } from '@shapeable/ui';
import { ExplorerProvider } from '../providers/explorer-provider';
import { PageExplorerLayout } from '../entities/page-explorer-layout';
const cls = classNames('page-layout-invited-contribution');

// -------- Child Component Props -------->

type ContainerProps = {

};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  `,
});

const LayoutStyles = breakpoints({
  base: css`
    flex-grow: 1;
  `,
  desktop: css`
    
  `,
});


// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Layout: styled(PageExplorerLayout)`${LayoutStyles}`,
};

export const PageLayoutInvitedContribution: PageLayoutComponent = 
(props) => {
  const { className, children, entity } = props;

  return (
    <ExplorerProvider>
    <My.Container className={cls.name(className)}>
      <My.Layout
        entity={entity}
      />
      {children}
    </My.Container>
    </ExplorerProvider>
  )
};