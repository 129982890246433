import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme, ColorTokens } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { MenuItem, UiComponentName, useComponentProps, useLink } from '@shapeable/ui';
import { ChallengeIcon } from '@shapeable/icons';


const cls = classNames('user-menu-space-item');

// -------- Types -------->

// -------- Props -------->

export type UserMenuSpaceItemProps = Classable & HasChildren & {
}

export const UserMenuSpaceItemDefaultProps: UserMenuSpaceItemProps = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  `,
});

// -------- Components -------->

const My = {
  NAME: 'UserMenuSpaceItem' as UiComponentName,

  Container: styled(MenuItem)<ContainerProps>`${ContainerStyles}`,
};

export const UserMenuSpaceItem: Shapeable.FC<UserMenuSpaceItemProps> = (props) => {
  const { 
    className, children, 
  } = useComponentProps(My.NAME, UserMenuSpaceItemDefaultProps, props) as UserMenuSpaceItemProps;

  const { navigate } = useLink();

  const handleSpacesNavigation = () => {
    navigate('/spaces/eco-augmentation');
  }
  
  return (
    <My.Container 
      className={cls.name(className)}
      onClick={handleSpacesNavigation}
      iconComponent={ChallengeIcon}
      label='My Spaces'
    />
  )
};

UserMenuSpaceItem.cls = cls;