import React from 'react';
import styled, { css } from 'styled-components';
import { PageLayoutComponent } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { PageHalfBanner, SiteHeaderLayout } from '@shapeable/ui';
import { ExplorerProvider } from '../providers/explorer-provider';
import { PageExplorerLayout } from '../entities/page-explorer-layout';
import { EntityTypeBannersProvider } from '@shapeable/ui';
const cls = classNames('page-layout-deep-dive');

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  `,
});

const SiteHeaderStyles = breakpoints({
  base: css`
    
  `,
});

const LayoutStyles = breakpoints({
  base: css`
    flex-grow: 1;
  `,
  desktop: css`
    
  `,
});


// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
  Header: styled(SiteHeaderLayout)`${SiteHeaderStyles}`,
    Layout: styled(PageExplorerLayout)`${LayoutStyles}`,
};

export const PageLayoutDeepDive: PageLayoutComponent = 
(props) => {
  const { className, children, entity } = props;

  return (
    <ExplorerProvider>
    <EntityTypeBannersProvider value={{
      Page: PageHalfBanner,
    }}>
    <My.Container className={cls.name(className)}>
      <My.Layout
        entity={entity}
        bannerProps={{ useBackgroundColor: true, subHeaderBackgroundColor: 'strong' }}
      />
      {children}
    </My.Container>
    </EntityTypeBannersProvider>
    </ExplorerProvider>
  )
};