import React from 'react';
import styled, { css } from 'styled-components';
import { PageLayoutComponent } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { ContentEntityIntro, EntityGrid, EntityTypeBannersProvider, PageHalfBanner } from '@shapeable/ui';
import { ExplorerProvider } from '../providers/explorer-provider';
import { useEvents } from '../../hooks/use-events';
import { EventCard } from '../entities/event-card';
import { PageExplorerLayout } from '../entities/page-explorer-layout';
import { sortBy } from 'lodash';
const cls = classNames('page-layout-workshops');

// -------- Child Component Props -------->

type ContainerProps = {

};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  `,
});

const IntroStyles = breakpoints({
  base: css`
    font-size: ${18/16}em;
  `,
});

const EventPagesStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(8)} 0;
  `,
});

const FooterStyles = breakpoints({
  base: css`
    
  `,
});

const ViewStyles = breakpoints({
  base: css`
    padding-top: ${theme.UNIT(4)};
  `,
});



// -------- Components -------->

const My = {
  Container: styled(PageExplorerLayout)`${ContainerStyles}`,
    View: styled.div`${ViewStyles}`,
    Intro: styled(ContentEntityIntro)`${IntroStyles}`,
    EventPages: styled(EntityGrid)`${EventPagesStyles}`,
};

export const PageLayoutWorkshops: PageLayoutComponent = 
(props) => {
  const { className, children, entity } = props;
  const events = sortBy(useEvents(), event => new Date(event.created), 'created').reverse();
  
  return (
    <ExplorerProvider>
    <My.Container 
      entity={entity}
      isAsideSticky
      className={cls.name(className)}
      content={
        <My.View>
          <My.Intro entity={entity} />
          <My.EventPages 
            items={events} 
            spacing={4}
            tabletAutoColumns={2} 
            desktopAutoColumns={3} 
            card={EventCard} 
          />
        </My.View>        
      }
    />
    </ExplorerProvider>
  )
};