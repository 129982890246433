import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react'; // tslint:disable-line
import { EventsProvider as Provider, platformProviderValue } from '@shapeable/ui';

const EVENTS_QUERY = graphql`
  query EventsQuery {
    platform {
      allEvents {
        id path slug name url __typename _schema { label pluralLabel }
        typeLabel
        created
        types { id name slug __typename }
        openGraph { description { plain } url title image { url thumbnails { card { url url2x } bubble { url url2x } small { url url2x } } } }
        color { id value darkValue }
        images { image { id url url2x width height } }
        trend { id name path outlineNumber color { id value } }
        trends { id name slug path outlineNumber color { id value } }
        topics { id name slug path outlineNumber color { id value } }
        roles { id name slug 
          role { id name }
          person {
            id name slug path __typename
            organisation { id name slug }
            position
            openGraph { image { thumbnails { bubble { url url2x } } } }
          }
        }
        sessions { 
          id name __typename 
          presenters { 
            id name 
            openGraph { image { thumbnails { bubble { url url2x } } } }
          }
        }
      }
    }
  }
`;

export const EventsProvider: React.FC<any> = ({ children }) => {
  return <Provider value={ platformProviderValue( useStaticQuery(EVENTS_QUERY), 'allEvents' ) }>{children}</Provider>;
};