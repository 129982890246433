const gql = String.raw;
  
export const Space = gql`
query SpaceBySlugQuery($slug: ID!) {
  spaceBySlug(slug: $slug) {
    
        
    
          id name slug typeLabel badge path updated __typename _schema { label pluralLabel }
    
        
        
              openGraph { 
                id title 
                description { plain text }
                image { 
                  id url url2x 
                  thumbnails { 
                    id bubbleLarge { id url url2x }
        mainBanner { id url url2x }
        halfBanner { id url url2x }
                  }
                  
                } 
              }
            
        
          entityType { useAi }
          entityViews {
            id name hash label slug count showCount
            type { id name slug }
            slices {
              id name slug label updated
              title 
              titleRich { id text }
              layout { id name slug component }
              themeMode { id name slug }
              openGraph {
                id
                image { url thumbnails { large { url } bubble { url url2x } } }
              }
              emptyTout { id text }
              ownEmptyTout { id text }
              entityFormFields {
                name
                label
                description
                ownDescription
                type
                formFieldType
                entityTypeName
                entityTypeNamePlural
              }
              headerFontType { internalName }
              bodyFontType { internalName }
              disabled
              connectedEntities { name label internalName }
              entityLayout { internalName }
              created updated
              outro { id text }
              intro { id text }
              content { id plain text }
              email
              phone
              twitter
              threads
              linkedin
              backgroundColor { id value }
              color { id value }
              teams { id name slug __typename _schema { label pluralLabel } }
              objectives { id name slug __typename type { id name slug __typename } }
              icon { id slug name component }
              embeds {
                citations {
                  slug url name authors { id name slug } 
                  authorShowsEtAl edition publication accessDate 
                  startPage volume footnoteNumber year
                }
                posts {
                  id name slug path
                  openGraph {
                    title description { plain(truncate: {words: 20}) }
                    image { url url2x type }
                  }
                }
                imageAssets {
                  id
                  slug
                  image {
                    id url url2x width height type 
                  }
                }
              }
              people {
                id name slug path __typename
                organisation { id name }
                linkedin
                position
                openGraph { id image { thumbnails { bubble { url url2x } card { url url2x } } } }
              }
              indicators { 
                id name slug title
              }
              indicatorGroups { 
                id slug name 
              }
              economies {
                id name slug path __typename _schema { label pluralLabel }
                openGraph { id image { url url2x thumbnails { bubble { url url2x } } } }
              } 
              locations { 
                id name slug __typename _schema { label pluralLabel } 
                openGraph { id image { url url2x thumbnails { bubble { url url2x } } } }
              }
              posts { 
                id name slug path __typename _schema { label  pluralLabel }
                type { id name slug }
                typeLabel
                openGraph { id image { url url2x thumbnails { bubble { url url2x } } } }
              }
              moderators {
                
                  
                
                    id name slug typeLabel badge path updated __typename _schema { label pluralLabel }
                
                  
                  
                        openGraph { 
                          id title 
                          
                          image { 
                            id url url2x 
                            thumbnails { 
                              id bubbleMedium { id url url2x }
                            }
                            
                          } 
                        }
                      
                
              }
              spaceMemberships {
                id name slug path __typename _schema { label  pluralLabel } 
                person {
                  id name slug path __typename
                  
                        openGraph { 
                          id title 
                          
                          image { 
                            id url url2x 
                            thumbnails { 
                              id bubbleMedium { id url url2x }
                            }
                            
                          } 
                        }
                      
                }
                role {
                  id name slug path __typename
                  
                        openGraph { 
                          id title 
                          
                          image { 
                            id url url2x 
                            thumbnails { 
                              id bubbleMedium { id url url2x }
                            }
                            
                          } 
                        }
                      
                }
              }
              organisationSpaceMemberships {
                id name slug path __typename _schema { label  pluralLabel }
                organisation {
                  
                    
                  
                      id name slug typeLabel badge path updated __typename _schema { label pluralLabel }
                  
                    
                    
                          openGraph { 
                            id title 
                            
                            image { 
                              id url url2x 
                              thumbnails { 
                                id bubbleMedium { id url url2x }
                              }
                              
                            } 
                          }
                        
                  
                }
                role {
                  
                    
                  
                      id name slug typeLabel badge path updated __typename _schema { label pluralLabel }
                  
                    
                    
                          openGraph { 
                            id title 
                            
                            image { 
                              id url url2x 
                              thumbnails { 
                                id bubbleMedium { id url url2x }
                              }
                              
                            } 
                          }
                        
                  
                }
              }
              feedEntries {
                name id slug path url __typename _schema { label pluralLabel }
                type { id name slug }
                typeLabel
                openGraph { id title image { url url2x thumbnails { full { url url2x } } } }
              }
              pages {
                id name slug path __typename _schema { label pluralLabel }
                type { id name slug }
                typeLabel
                openGraph { id image { url url2x thumbnails { bubble { url url2x } } } }
              }
              organisations { 
                id name slug path __typename _schema { label pluralLabel }
                openGraph { id image { url url2x thumbnails { bubble { url url2x } card { url url2x } } } }
              }
              trends {
                
                  id name slug typeLabel badge path updated __typename _schema { label pluralLabel }
                
                outlineNumber
                color { __typename id slug name value }
                openGraph { image { thumbnails { bubble { url url2x } card { url url2x } } } }
              }
              topics {
                
                  id name slug typeLabel badge path updated __typename _schema { label pluralLabel }
                
                outlineNumber
                color { __typename id slug name value }
                openGraph { image { thumbnails { bubble { url url2x } card { url url2x } } } }
              }
              subTopics {
                
                  id name slug typeLabel badge path updated __typename _schema { label pluralLabel }
                
                outlineNumber
                color { __typename id slug name value }
                openGraph { image { thumbnails { bubble { url url2x } card { url url2x } } } }
              }
              topicExpertise {
                
                  id __typename typeLabel badge name slug
                  levelOfExpertise { id name slug }
                  topics { 
                    __typename outlineNumber id name slug path 
                    trend { id slug }
                    openGraph {
                      id
                      title description { plain }
                      image { url url2x thumbnails { medium { url url2x } } }
                    }
                  }
                
              }
              trendExpertise {
                
                  id __typename typeLabel badge name slug
                  levelOfExpertise { id name slug }
                  trends { 
                    __typename outlineNumber id path name slug
                    openGraph { 
                      id title description { plain } image { url url2x thumbnails { medium { url url2x } } }
                    }
                  }
                
              }
            }
          }
        
        type { id name slug }
        statuses { id name slug }
        agenda { id text }
        moderators {
          
            id name slug typeLabel badge path updated __typename _schema { label pluralLabel }
           
          position
          openGraph { image { thumbnails { bubble { url url2x } card { url url2x } } } }
          organisation { id name slug }
        }
        events { 
           
            id name slug typeLabel badge path updated __typename _schema { label pluralLabel } 
          
          openGraph { image { thumbnails { bubble { url url2x } card { url url2x } } } }
        }
        trends {
          
            id name slug typeLabel badge path updated __typename _schema { label pluralLabel }
          
          outlineNumber
          color { __typename id slug name value }
          openGraph { image { thumbnails { bubble { url url2x } card { url url2x } } } }
        }
        topics {
          
            id name slug typeLabel badge path updated __typename _schema { label pluralLabel }
          
          outlineNumber
          color { __typename id slug name value }
          openGraph { image { thumbnails { bubble { url url2x } card { url url2x } } } }
        }
        subTopics {
          
            id name slug typeLabel badge path updated __typename _schema { label pluralLabel }
          
          outlineNumber
          color { __typename id slug name value }
          openGraph { image { thumbnails { bubble { url url2x } card { url url2x } } } }
        }
        members {
          id name slug path __typename _schema { label pluralLabel }
          openGraph { image { thumbnails { bubble { url url2x } card { url url2x } } } }
        }
        organisationMembers {
          id name slug path __typename _schema { label pluralLabel }
          openGraph { image { thumbnails { bubble { url url2x } card { url url2x } } } }
        }
    
    banner {
      id name slug title description { text } alternateText
      image { 
        id url url2x width height thumbnails { halfBanner { url url2x } } 
      }
    }
  }
}
`;
